import React, { useRef, useState } from "react";
import { useSlateStatic, ReactEditor } from "slate-react";
import { Transforms, Editor, Element } from "slate";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppHeaderPopup from "./AppHeaderPopup";
import { getTRBLBreakPoints } from "../../helper/theme";
import { handleLinkType } from "../../utils/helper";

const drawerWidth = 240;

function AppHeader(props) {
  const navWrprRef = useRef(null);
  const [openSetttings, setOpenSettings] = useState(false);
  const { attributes, element, customProps, children } = props;
  const { readOnly } = customProps;
  const {
    appTitle,
    appLogo,
    menus,
    bgColor,
    bgColorHover,
    textColor,
    textColorHover,
    menuStyle,
    bannerSpacing,
    fontSize,
    fontFamily,
    logoFontSize,
    titleFontFamily,
    isLogoRight,
    logoHeight = 40,
  } = element;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const isDrawer = menuStyle === "drawer";

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const onSettings = (e) => {
    if (!readOnly) {
      e.stopPropagation();
      e.preventDefault();
      setOpenSettings(true);
    }
  };

  // const ToolBar = () => {
  //   return customProps?.readOnly !== true ? (
  //     <div
  //       className="element-toolbar"
  //       contentEditable={false}
  //       style={{ top: "0px", right: "0px", zIndex: 1000 }}
  //     >
  //       <Tooltip title="App Header Settings" arrow>
  //         <IconButton size="small" onClick={onSettings}>
  //           <GridSettingsIcon />
  //         </IconButton>
  //       </Tooltip>
  //     </div>
  //   ) : null;
  // };

  const onSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(editor, updateData, { at: path });
    onClose();
  };

  const onDelete = (e) => {
    e.stopPropagation();
    Transforms.removeNodes(editor, { at: path });
  };

  const onClose = () => {
    setOpenSettings(false);
  };

  const handleFocus = (e) => {
    if (!readOnly) {
      e.preventDefault();
      try {
        const [[, gridItemPath]] = Editor.nodes(editor, {
          at: path,
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === "grid-item",
        });
        if (gridItemPath) {
          Transforms.select(editor, gridItemPath);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // const onMenuSettings = (menuItem, i) => (event) => {
  //   event.preventDefault();
  // };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2, color: textColor }}>
        {appLogo && appLogo !== "none" ? (
          <img
            alt={`${appTitle} Logo`}
            style={{ height: `${parseInt(logoHeight) || 40}px`, width: "auto" }}
            src={appLogo}
          />
        ) : (
          appTitle
        )}
      </Typography>
      <Divider />
      <List>
        {menus.map((item, i) => {
          const buttonProps = handleLinkType(
            item.url,
            item.linkType,
            true,
            item.target === "_blank"
          );

          return (
            <ListItem key={`${item.text}_${i}`} disablePadding>
              <ListItemButton {...buttonProps} sx={{ textAlign: "center" }}>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  // const elevateStyle = useEleveateScroll({
  //   ...props,
  //   parentRef: navWrprRef,
  //   needElevation: readOnly,
  // });

  const elevateStyle = { position: "relative" };

  return (
    <>
      <Box
        sx={{ display: "flex", position: "relative" }}
        {...attributes}
        contentEditable={false}
        onClick={handleFocus}
      >
        <div ref={navWrprRef} style={{ display: "flex", width: "100%" }}>
          <CssBaseline />
          <AppBar
            component="nav"
            sx={{
              ...elevateStyle,
              background: bgColor,
              boxShadow: "none",
              padding: {
                ...getTRBLBreakPoints(bannerSpacing),
              },
              zIndex: 999,
            }}
            onClick={onSettings}
          >
            <Toolbar
              style={{
                flexDirection: isLogoRight ? "row-reverse" : "row",
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <IconButton
                className="app-drawer-btn"
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                style={{ color: textColor }}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                color={"primary"}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  color: textColor,
                  fontSize: logoFontSize,
                  fontFamily: titleFontFamily,
                  justifyContent: isLogoRight ? "end" : "start",
                }}
                sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                className="app-logo"
              >
                {appLogo && appLogo !== "none" ? (
                  <img
                    alt={`${appTitle} Logo`}
                    style={{
                      height: `${parseInt(logoHeight) || 40}px`,
                      width: "auto",
                    }}
                    src={appLogo}
                  />
                ) : null}
                {appTitle}
              </Typography>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {isDrawer ? (
                  <IconButton
                    className="app-drawer-btn"
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    style={{ color: textColor }}
                  >
                    <MenuIcon />
                  </IconButton>
                ) : null}

                {!isDrawer
                  ? menus.map((item, i) => {
                      const buttonProps = handleLinkType(
                        item.url,
                        item.linkType,
                        true,
                        item.target === "_blank"
                      );

                      return (
                        <Button
                          {...buttonProps}
                          key={`${item.url}_${i}_no_drawer`}
                          sx={{
                            position: "relative",
                            fontFamily: fontFamily,
                            textTransform: "none",
                            fontSize: fontSize || "16px",
                            color: textColor || "#FFF",
                            background: bgColor || "none",
                            "& .m-settings": {
                              display: "none",
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              margin: "auto",
                              width: "42px",
                              height: "42px",
                              background: "#FFF",
                            },
                            "&:hover": {
                              color: textColorHover || textColor || "#FFF",
                              background: bgColorHover || bgColor || "none",
                              "& .m-settings": {
                                display: "block",
                              },
                            },
                          }}
                        >
                          {item.text}
                          {/* {!readOnly && (
                        <Tooltip
                          title="Menu Settings"
                          onClick={onMenuSettings(item, i)}
                        >
                          <IconButton className="m-settings">
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                      )} */}
                        </Button>
                      );
                    })
                  : null}
              </Box>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              anchor={isLogoRight ? "right" : "left"}
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: isDrawer ? "block" : "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
          {/* <ToolBar /> */}
        </div>
        <div contentEditable={false} style={{ display: "none" }}>
          {children}
        </div>
      </Box>
      {openSetttings ? (
        <AppHeaderPopup
          element={element}
          onSave={onSave}
          onClose={onClose}
          onDelete={onDelete}
          customProps={customProps}
        />
      ) : null}
    </>
  );
}

export default AppHeader;
