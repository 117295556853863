import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSlate } from "slate-react";

const sectionTypes = ["grid"];

const loopChildren = (children = [], sections) => {
  if (!children?.length) {
    return sections;
  }

  for (let child of children) {
    if (sectionTypes.includes(child?.type)) {
      if (child.id) {
        sections.push(child.id);
      }
    }

    sections = loopChildren(child.children, sections);
  }

  return sections;
};

const OpenInNewTab = (props) => {
  const { nav, openInNewTab, onNewTabChange } = props;

  return nav?.showOpenInNewTab ? (
    <FormControlLabel
      className="ccheckbox-primary"
      control={<Checkbox checked={openInNewTab} onChange={onNewTabChange} />}
      label={<Typography variant="body2">Open in new tab</Typography>}
    />
  ) : null;
};

export const TextInput = (props) => {
  return (
    <>
      <TextField
        fullWidth
        size="small"
        {...props}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      />

      <OpenInNewTab {...props} />
    </>
  );
};

export const SelectPage = (props) => {
  const { value, onChange, services } = props;
  const [pages, setPages] = useState([]);
  const editor = useSlate();

  const getPages = async () => {
    const result = await services("getPages", {});

    if (result?.data?.length) {
      const refactor = result?.data?.map((r) => {
        const { title, url_name, ...rest } = r;
        return { label: url_name, value: url_name, ...rest };
      });

      setPages(refactor);
    } else {
      const currentPage = {
        label: "Current Page",
        value: "_currentPage",
        is_current_page: 1,
        sections: loopChildren(editor.children, []),
      };

      setPages([currentPage]);

      if (!value) {
        onChange(currentPage.value);
      }
    }
  };

  useEffect(() => {
    getPages();
  }, []);

  const [page, section] = useMemo(() => {
    if (value) {
      const [page, section] = value.split("#");

      const selectedPage = pages.find((p) => p.value === page) || {
        label: page,
        value: page,
      };
      const selectedSection = pages.find((p) => p.value === section) || {
        label: section,
        value: section,
      };

      return [selectedPage, selectedSection];
    }

    return [];
  }, [value, pages]);

  const isCurrentPage = page?.value === "_currentPage";

  return (
    <div>
      <FreeSoloCreateOption
        label={page?.label}
        setValue={(val) => onChange(val?.value || "")}
        placeholder="Select Page"
        options={pages}
        disabled={isCurrentPage}
      />

      <FreeSoloCreateOption
        label={section?.label}
        setValue={(val) => {
          let url = page?.value;

          if (val?.value) {
            url += `#${val?.value}`;
          }

          onChange(url);
        }}
        placeholder="Select Section or anchor"
        options={page?.sections?.map((p) => ({ label: p, value: p }))}
      />

      {isCurrentPage ? null : <OpenInNewTab {...props} />}
    </div>
  );
};

export const Trigger = (props) => {
  return (
    <Typography variant="subtitle1" gutterBottom>
      Choosing this will trigger the next step
    </Typography>
  );
};

const scrollToOptions = [
  { label: "Top", value: "top" },
  { label: "Bottom", value: "bottom" },
];

export const ScrollTopBottom = (props) => {
  const { value, onChange } = props;

  return (
    <>
      <Typography variant="body2" sx={{ paddingBottom: "4px" }}>
        Choose Top/Bottom of page
      </Typography>

      <Select
        size="small"
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {scrollToOptions.map((option, i) => {
          return (
            <MenuItem value={option.value} key={i}>
              <Typography variant="body2">{option.label}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

const filter = createFilterOptions();

export function FreeSoloCreateOption({
  label,
  setValue,
  options = [],
  placeholder = "",
  disabled = false,
}) {
  return (
    <Autocomplete
      freeSolo
      options={options}
      value={label || ""}
      renderInput={(params) => <TextField {...params} label={placeholder} />}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            value: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          const { inputValue } = newValue;

          // Create a new value from the user input
          setValue({
            label: inputValue,
            value: inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.label
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.value;
      }}
      sx={{ marginTop: "10px" }}
      disabled={disabled}
    />
  );
}
